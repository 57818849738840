<template>
  <form id="frm_action">
    <div class="p-formgrid">
      <div class="col-6">
<!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Property</label>
          <div class="p-col">
            <v-select :clearable="false" title="Property" class="" @search="SearchProperty" placeholder="Choose" v-model="obj_data.property_id" :options="listProperty" :reduce="property_item => property_item.id" label="name"  >
              <span slot="no-options" @click="$refs.select.open = false">
                Empty data
              </span>
            </v-select>
            <input class="input_tmp_validator" id="property">
          </div>
        </div>-->

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Fullname</label>
          <div class="p-col">
            <InputText maxlength="50" id="name" type="text" class="" v-model="obj_data.fullname"  placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Email</label>
          <div class="p-col">
            <InputText maxlength="50" id="email" type="text" class="" v-model="obj_data.email"  placeholder="Input"/>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Phone number</label>
          <div class="p-col ">
            <div class="style-box-phone">
              <input class="input_tmp_validator" id="prefix_phone">
              <Dropdown  v-model="obj_data.prefix_phone" :options="list_phone_code" optionLabel="prefix_phone" optionValue="prefix_phone"/>
              <InputText type="number" id="phone" class="" v-model="obj_data.phone"  placeholder="Input"/>
            </div>
          </div>
        </div>
        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Available for use from</label>
              <div class="p-col">
                <Calendar
                    :showOnFocus="false"
                    dateFormat="MM dd, yy"
                    :showIcon="true"
                    appendTo="body"
                    :showClear="true"
                    :manualInput="false"
                    v-model="obj_data.available_from" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="available_from"/>
              </div>
            </div>

            <div class="col-6">
              <label class="p-col-fixed txt-right">To</label>
              <div class="p-col">
                <Calendar
                    :showOnFocus="false"
                    dateFormat="MM dd, yy"
                    id="end_booking_date"
                    :showIcon="true"
                    appendTo="body"
                    :showClear="true"
                    :manualInput="false"
                    v-model="obj_data.available_to"
                    placeholder="Choose"
                />
                <input class="input_tmp_validator" id="available_to"/>
              </div>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Password</label>
          <div class="p-col">
            <div class="box-password-style" >
              <InputText maxlength="255" id="password" :type="passwordType" class="" v-model="obj_data.password"
                         placeholder="Input" />
              <i  v-if="passwordType === 'text'" class="material-icons lg-icon-password" @click="switchVisibility">visibility</i>
              <i  v-else class="material-icons lg-icon-password" @click="switchVisibility">visibility_off</i>
            </div>
          </div>
        </div>
        <div class="p-card-field">
          <label class="p-col-fixed txt-right txt-required">Re-enter password</label>
          <div class="p-col">
            <div class="box-password-style" >
              <InputText maxlength="255" id="Re_enter_password" :type="passwordReType" class="" v-model="obj_data.re_password"
                         placeholder="Input" />
              <i  v-if="passwordReType === 'text'" class="material-icons lg-icon-password" @click="switchVisibility($event,'re')">visibility</i>
              <i  v-else class="material-icons lg-icon-password" @click="switchVisibility($event,'re')">visibility_off</i>
            </div>
          </div>
        </div>

        <div class="p-card-field">
          <label class="p-col-fixed txt-right ">Avatar</label>
          <div class="p-col">
            <div class="cus-text">Recommend: 500x500px  Maximum:10 MB</div>
            <div class="cus-text">Accepted file:JPG, JPEG, PNG, GIF.</div>
            <input class="p-inputtext p-component"  accept="image/*" name="images[]" id="images_service" type="file" @change="onFileSelectFile"/><!--@change="onFileSelectLogo"-->
            <input class="input_tmp_validator" id="list_file">
            <div class="cus-text style_list_image">
              <div class="box-image" v-for="(item, index) in list_file" :key="index">
                <img class="style_img" :src="item.objectURL"/>
                <div class="button-clear" @click="onFileClear(item, index)"><img
                    src="/assets/images/icon/cancel_black_24dp.png" /></div>
              </div>
<!--              <span :key="comFilename">{{ obj_data.filename }}</span>-->
            </div>

            <!--            <div class="select-button" style="background-color: rgb(227, 237, 252);height: max-content;text-align: justify;justify-items: center;display: flex;padding: 0.55rem;margin: -0.429rem;">
              <span style="margin: auto;">Tải lên</span>
            </div>-->
          </div>
        </div>
        <div class="p-card-field div_check">
          <div class="p-col checkbox">
            <Checkbox  v-model="obj_data.active"  :binary="true"/>
            <label class="p-col-fixed txt-right text">Active</label>
          </div>
        </div>

<!--        <div class="p-card-field">
          <label class="p-col-fixed txt-right" :class="`${mode=='add' ? 'txt-required' : ''}`">From - to</label>
          <div class="p-col">
            <Calendar 
              :showOnFocus="false" 
              dateFormat="MM dd, yy" 
              class=""
              :showIcon="true" 
              selectionMode="range"
              appendTo="body"
              :showClear="true" 
              :manualInput="false"
              v-model="from_to" :disabled="mode=='edit'" placeholder="Choose"
            />
            <input class="input_tmp_validator" id="from_to"/>
          </div>
        </div>-->
<!--        <div class="p-card-field">
          <div class="row">
            <div class="col-6">
              <label class="p-col-fixed txt-right txt-required">Start booking</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  v-model="start_booking_date" placeholder="Choose"
                />
                <input class="input_tmp_validator" id="start_booking_date"/>
              </div>
            </div>
              
            <div class="col-6">
              <label class="p-col-fixed txt-right">End</label>
              <div class="p-col">
                <Calendar 
                  :showOnFocus="false" 
                  dateFormat="MM dd, yy" 
                  id="end_booking_date"
                  :showIcon="true" 
                  appendTo="body"
                  :showClear="true" 
                  :manualInput="false"
                  :disabled="true"
                  v-model="end_booking_date"
                  placeholder="Choose"
                />
              </div>
            </div>
          </div>
        </div>-->
<!--        <div class="p-card-field">
          <h3>ORDER OF BOOKING ({{partArr.length}}/5)</h3>
        </div>
        <packageDescriptions :key="componentKey" v-model="partArr" :mode="mode"/>
        
        <matrixComponent @updateMatrix="updateMatrix" v-model="partArr" :dataBook="dataBookingRound" :show="true" :property="selProperty" :key="`matrix-${componentKey}`"/>
        
        <div v-if="mode == 'edit'" class="p-card-field">
          <a @click="toCalendar" class="txt-link pl-3"><i>View on calendar</i></a>
        </div>-->
      </div>
      <Toolbar class="fixed-bottom-toolbar div-button">
        <template slot="right">
          <Button
              :label="$constants.TEXT_BUTTON.BACK"
              @click="backToList()"
              class="p-button-outlined"
              id="back"
          />
          <Button
              v-if="mode == 'edit'"
              :label="$constants.TEXT_BUTTON.EDIT"
              @click="saveData()"
              class="main-style-button"
          />
          <Button
              v-else
              :label="$constants.TEXT_BUTTON.ADD"
              @click="saveData()"
              class="main-style-button"
          />
        </template>
      </Toolbar>
    </div>
  </form>
</template>
<script>
import moment from "moment-timezone";
import DataServices from '@/core/DataServices'
export default {
  components: {

  },
  props: {
    mode: String,
    model: Object,
    users: Array
  },
  data() {
    return {
      list_id_file_delete:[],

      passwordType: 'password',
      passwordReType: 'password',

      obj_data:{
        filename:"",
        prefix_phone:"",
      },
      comFilename:1,
      list_phone_code: [
        {
          prefix_phone:"+84",
        },
        {
          prefix_phone:"+45",
        }
      ],
      dataBookingRound: [],
      selProperty: null,
      listProperty: [],
      listPropertyAll: [],
      listDocsCate: [],
      listDocsCateAll: [],
      from_to: null,
      address: null,
      start_booking_date: null,
      end_booking_date: null,
      partArr: [],
      time_zone_local : moment.tz.guess(true),
      componentKey: 0,
      list_file :[],
      maxFileSize: 2097152*50 , //100MB  2MB   /*104857600, // 100MB*/
    }
  },
  watch: {
   /* async "selProperty"() {
      if (!this.$commonFuction.isEmpty(this.selProperty)) {
        this.address = this.selProperty?.address;
        this.partArr = this.selProperty?.part;
        if (this.mode == 'add') {
          var arr = [];
          this.partArr.map(m => {
            arr.push({ord: m.order_booking, id: m.id});
          })
          arr.map(m=> {
            var tmp = [];
            for (let i =0; i < arr.length; i++) {
              let a = m.ord - i + arr.length;
              if (a > arr.length) {
                a = a - arr.length;
              }
              tmp.push({ordinal: a, part_id: m.id, round: (i+1)});
            }
            this.dataBookingRound.push(tmp);
          })
        }
        this.componentKey++;
      }
    },
    "start_booking_date"() {
      if (this.partArr.length) {
        let days = 14 * this.partArr.length;
        this.end_booking_date = moment.tz(this.start_booking_date, this.time_zone_local).add(days, 'days').format("LL");
      }
    }*/
  },
  async mounted() {
    /*await this.getListProperty();
    await this.getListDocsCate();*/
    console.log("this.model:",this.model)
    if (this.model !== undefined && Object.keys(this.model).length > 0) {
      this.obj_data = this.model;
      this.obj_data.filename = this.model?.item_avatar?.file_name ?? ''
      if (!this.$commonFuction.isEmpty(this.model?.item_avatar)) {
        let url = this.model?.item_avatar?.url
        let obj = {'name': this.obj_data.filename, 'file': '', 'objectURL': url, type: 'old_edit'}//type:'old_edit' nếu gặp type này ko update avatar
        this.list_file.push(obj)
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_from)) {
        this.obj_data.available_from = moment.tz(this.obj_data.available_from, this.time_zone_local).format("LL");
      }
      if (!this.$commonFuction.isEmpty(this.obj_data.available_to)) {
        this.obj_data.available_to = moment.tz(this.obj_data.available_to, this.time_zone_local).format("LL");
      }

      this.componentKey++;
    }else {
      //thêm mới
      this.obj_data = {
        /*fullname: "Tuyen",
        email: "asdasdaskdjkask@gmail.com",
        phone: "234434535",
        password: "123456",
        re_password: "123456",*/

        //default data
        prefix_phone: "+45",
        active: true,
        ordinal: 1,
      };
      this.componentKey++;
    }
  },
  methods: {
    switchVisibility(event,type=null) {
      console.log("event:",event)
      console.log("typetype:",type)
      if (type=='re'){
        this.passwordReType = this.passwordReType === 'password' ? 'text' : 'password'
      }else {
        this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
      }
    },
    onFileClear(item, index) {
      console.log('itemitem:', item)
      var type = item?.type ?? null
      if (type == 'old_edit') {
        this.list_id_file_delete.push(item.id);
      }
      this.list_file.splice(index, 1);
    },
    async onFileSelectFile(event) {
      var that = this;
      let files = event.target.files || event.dataTransfer.files;
      console.log("filesfiles:",files)
      console.log("files.length:",files.length)
      if(files.length > 0) {
        let acceptTags = ['jpeg', 'jpg', 'gif', 'png', 'JPEG', 'JPG', 'PNG', 'GIF', 'svg', 'SVG']
        console.log("files.length1111111:",files.length)
        for (let i = 0; i < files.length; i++) {
          console.log("files.333333333333:",files.length)
          if (!acceptTags.includes(files[i].name.split('.').pop())) {
            alert("Only post formats are supported: jpeg, jpg, gif, png, svg");
            return;
          }
        //  document.getElementById('file_name').innerText = files[i].name;
          console.log("files[i].namefiles[i].name:",files[i].name)
          that.obj_data.filename =files[i].name;
          this.comFilename++;
        }
        var arrTemp = [], arrFiles = [];
        for (let file of files) {
          console.log('file.sizefile.sizefile.size:',file.size)
          if (file.size <= this.maxFileSize) {
            file.source = file;
            file.objectURL = window.URL.createObjectURL(file);
            console.log("asdasdasfile:",file)
            arrTemp.push(file);
          }
          if (file.size > this.maxFileSize) arrFiles.push(file);
        }
        if (arrFiles.length > 0) {
          alert("Your image is larger 100MB");
          that.obj_data.filename = "";
          return;
        } else {
          // if (this.icon_service.length > 0 && this.icon_service[0].file === '') {
          //   this.imagesDeletes.push(this.icon_service[0])
          // }
          console.log("arrTemparrTemp:",arrTemp)
          this.list_file = [];
          this.list_file = this.list_file.concat.apply(this.list_file, arrTemp);
        }
      }
    },
    isImage(file) {
      return /^image\//.test(file.type);
    },
    updateMatrix(data) {
      console.log(data);
      this.dataBookingRound = [...data];
      // this.componentKey++;
    },
    async toCalendar() {
      this.$router.push(`/admin/list-booking?view=calendar&propery_id=${this.selProperty?.id}`);
    },
    async getListProperty(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListProperty(where_key);
      this.listProperty =list.list;
      this.listPropertyAll = list.list;
    },
    
    async SearchProperty(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListProperty(where_key);
        this.listProperty = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listProperty = this.listPropertyAll
      }
    },

    async getListDocsCate(){
      var where_key = {
        deleted : {_eq : false},
        // booking: {id: {_is_null: true} },
      }
      var list = await this.$CoreService.getListDocsCate(where_key);
      this.listDocsCate =list.list;
      this.listDocsCateAll = list.list;
    },

    async SearchDocsCate(search, loading){
      console.log(loading);
      if(search !== '' && search !== null){
        var where_key = {
          deleted : {_eq : false},
        };
        if (!this.$commonFuction.isEmpty(search)){
          where_key.name = {
            _iregex: this.$commonFuction.search(search),
          };
        }
        var list = await this.$CoreService.getListDocsCate(where_key);
        this.listDocsCate = list.list;
        //console.log("this.listProperty:",this.listProperty)
      }else{
        // cosnole.log("dasdasd")
        this.listDocsCate = this.listDocsCateAll
      }
    },

    backToList() {
      this.$emit('back');
    },
    async validateData() {
      this.validationErrors = {};


      let name_add = document.getElementById("name");
      if (this.$commonFuction.isEmpty(this.obj_data.fullname)){
        name_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      }else {
        name_add.setCustomValidity("");
      }

      let email_add = document.getElementById("email");
      if (this.$commonFuction.isEmpty(this.obj_data.email)){
        email_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      }else {
        // console.log("this.IsEmail(this.email)",this.IsEmail(this.email))
        if(await this.$commonFuction.IsEmail(this.obj_data.email)){
          var checkEmail = await this.checkEmail(this.obj_data.email);
          // console.log("checkEmail",checkEmail)
          if(checkEmail == true){
            email_add.setCustomValidity("Email already exists!");
          }else{
            email_add.setCustomValidity("");
          }
        }else{
          email_add.setCustomValidity("Email is not in correct format!");
        }
      }
      let phone_add = document.getElementById("phone");
      let prefix_phone = document.getElementById("prefix_phone");
      if (this.$commonFuction.isEmpty(this.obj_data.phone)){
        phone_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
      }else {
        if(this.obj_data.phone.toString().length < 8){
          phone_add.setCustomValidity("Please enter a Phone number of at least 8 characters!");
        }else{
          if(this.obj_data.phone.toString().length > 15){
            phone_add.setCustomValidity("Please enter a Phone Number with at most 15 characters!");
          }else{
            //kiểm tra
            phone_add.setCustomValidity("");
          }
        }
      }

      if (this.$commonFuction.isEmpty(this.obj_data.prefix_phone)) {
        prefix_phone.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      } else {
        prefix_phone.setCustomValidity("");
      }

      let available_from_add = document.getElementById("available_from");
      if (this.$commonFuction.isEmpty(this.obj_data.available_from)){
        available_from_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      }else {
        available_from_add.setCustomValidity("");
      }

      let available_to_add = document.getElementById("available_to");
      if (this.$commonFuction.isEmpty(this.obj_data.available_to)){
        available_to_add.setCustomValidity(this.$constants.MSG_TEXT.SELECT_INVALID_MSG);
      }else {
        available_to_add.setCustomValidity("");
      }

      if (this.mode == 'add') {
        let password_add = document.getElementById("password");
        if (this.$commonFuction.isEmpty(this.obj_data.password)){
          password_add.setCustomValidity(this.$constants.MSG_TEXT.INPUT_INVALID_MSG);
        }else {
          if(this.obj_data.password.length < 6){
            password_add.setCustomValidity("Please enter a password of at least 6 characters!");
          }else{
            //kiểm tra
            password_add.setCustomValidity("");
          }
        }
      }
      let Re_enter_password = document.getElementById("Re_enter_password");
      if (!this.$commonFuction.isEmpty(this.obj_data.password) || !this.$commonFuction.isEmpty(this.obj_data.re_password)){
        //check password khớp
        console.log('this.obj_data.password:',this.obj_data.password)
        console.log('this.obj_data.re_password:',this.obj_data.re_password)
        if (this.obj_data.password != this.obj_data.re_password){
          Re_enter_password.setCustomValidity("Passwords do not match!");
        }else {
          Re_enter_password.setCustomValidity("");
        }
      }
/*
      let list_file_add = document.getElementById("list_file");
      if (this.$commonFuction.isEmpty(this.obj_data.filename)){
        list_file_add.setCustomValidity(this.$constants.MSG_TEXT.FILE_INVALID_MSG);
      }else {
        list_file_add.setCustomValidity("");
      }*/

      var inpObj = document.getElementById("frm_action");
      if (!inpObj.checkValidity()){
        console.log('test=====================');
        inpObj.reportValidity();
        return true;
      }
    },
    async checkEmail(email){
     // if(this.email !== null && this.email !== ''){
        var where = {
          email: {'_eq': email},
          deleted: {'_eq': false},
         // type: {'_eq' : 'user'}
        }
        if (this.model !== undefined) where = Object.assign(where, {id: {'_neq': this.model.id}})
        var {data} = await this.$apollo.query({
          query: DataServices.getList('users', {"fields" : "id, email"}),
          variables: {
            where_key: where
          },
          fetchPolicy: "network-only"
        })
        // console.log(data)
        return data && data[Object.keys(data)].length > 0
      //}
    },
    async saveData() {
      this.$commonFuction.is_loading(true);
      if (await this.validateData()) {
        this.$commonFuction.is_loading(false);
        return
      }
      //console.log("this.from_to", this.from_to);
      //console.log("partArr", this.dataBookingRound);
      var params = this.obj_data
      params["list_id_file_delete"] = this.list_id_file_delete
      params["list_file"] = this.list_file
      console.log('saveData', params)
      this.$emit('save', params)
    },
  },
}
</script>

<style lang="scss">
.p-multiselect {
  width: 450px;
}
.p-multiselect-label:not(.p-placeholder) {
  padding-top: .25rem;
  padding-bottom: .25rem;

}
.category-item-value {
  padding: .25rem .5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: .2rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}
.p-multiselect-label-container{
  height: 40px;
}
.p-inputtext{
  margin:  0px 0px 0px 4px;
}

//style form add-edit
@media screen and (min-width: 1001px) {
  .input_distance {
    width: 728px !important;
  }
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.p-col-fixed {
  padding: 0px !important;
  margin:0px 0px 0px 9px !important;
}
.input_text{
  width: 760px!important;
  height: 48px!important;
}
.txt-right{
  font-weight: bold;
}

.multiselect{
  width: 760px!important;
}
.layout-main{
  background: #00000029;
}

.vs__clear{
  display: none;
}
.input_width{
  width: 760px!important;
  height: 48px!important;
}
#distance{
  width: 1px!important;
  height: 48px!important;
  border-left: 0px;
  border-top: 0px;
  border: 1px solid #C8C8C8;
  border-radius: 0px;
  //border-top: none!important;
  //border: 1px solid rgba(60, 60, 60, 0.26);;
}
.manager-title{
  font-size: 24px!important;
}
.input-dif{
  margin-left: 5px!important;
  border-top: 0px;
}
.p-inputgroup-addon{
  height: 48px ;
}
.txt-right{
  font-weight: bold;
}
.p-col-fixed {
  padding: 0px !important;
  //margin:0px 0px 0px 10px !important;
}
label.p-col-fixed txt-right{
  margin-bottom: 0px!important;
}
div.p-card-field{
  padding: 0px!important;
}
.margin{
  margin-bottom: 12px!important;
}

.border_color{
  border: 1px solid #C8C8C8;
}
.text{
  font-weight: normal!important;
  margin-left: 15px!important;
}
.div_check{
  margin-top: 12px!important;
}


//responsive
@media screen and (max-width: 600px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
    width: 100%!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }

}

@media screen and (max-width: 1000px) {
  .input_width{
    width: 100%!important;
  }
  .div-button{
    padding: 8px!important;
  }
  .p-inputgroup-addon{
    margin-right: 12px;
  }
  #distance{
    text-align: left!important;
    padding: 6.8px;
  }
}
.p-button-outlined {
  .p-button-label {
    color: #007ad9 !important;
  }
}
.cus-text{
  margin-left: 2px;
  margin-top: 4px;
}
.style-box-phone{
  border: 1px solid #a6a6a6;
  width: 100%;
  display: flex;
.p-dropdown{
  width: 100px;
  border: 0px;
  box-shadow: none !important;
}
  .p-focus{
    border: 0px;
    box-shadow: none ;
  }
  .p-dropdown:focus{
    border: 0px;
    box-shadow: none;
  }
  input{
    width: calc(100% - 100px);
    margin-left: 0px;
    border: 0px;
  }
  .p-inputtext:enabled:focus {
    border: 0px;
    box-shadow: none;
  }
}
.style_list_image {
  display: flex;
  flex-wrap: wrap; /* Cho phép các phần tử xuống dòng */
  gap: 10px; /* Khoảng cách giữa các phần tử */
  .box-image {
    width: 100px;
    height: 100px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    .style_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid #D0D4D9;
      border-radius: 10px;
    }
    .button-clear{
      width: 20px;
      height: 20px;
      position: absolute;
      top: 1px;
      right: 8px;
    }

  }
}

.box-password-style{
  width: 100%;
  position: relative;
  .lg-icon-password {
    margin-top: -30px;
    display: block;
    position: absolute;
    right: 10px;
    padding: auto 5px;
    &:hover {
      cursor: pointer;
    }
  }
}

</style>
